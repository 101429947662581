import React from 'react';

import { HeroBanner, CaseHeader, TextContent, Gallery, GalleryLayout, Team } from 'modules/references';

import socialPreview from '../../../static/social-previews/weshare.png';
import Seo from '../../components/SEO';
import { ReferenceCategory } from '../../constants';

const team = [
    { department: 'references.case.team.management', names: ['Dominik Veselý'] },
    {
        department: 'references.case.team.backend',
        names: ['Štefan Prokop'],
    },
    {
        department: 'references.case.team.ios',
        names: ['Lukáš Hromadník'],
    },
    { department: 'references.case.team.web', names: ['Jiří Čermák', 'Filip Kubík'] },
    {
        department: 'references.case.team.android',
        names: ['Vlad Gorbunov', 'Georgyi Shur'],
    },
    {
        department: 'references.case.team.devops',
        names: ['Tomáš Hejátko'],
    },
];

const WeShare = () => (
    <>
        <Seo image={socialPreview} pageId="weshare" />
        <CaseHeader />
        <HeroBanner
            content={{
                projectName: 'case.weshare.hero.project',
                projectTitle: 'case.weshare.hero.title',
                projectYear: 'case.weshare.hero.year',
                client: 'case.weshare.hero.client',
                image: {
                    src: 'references/case-studies/weshare/hero_image.png',
                    style: { height: '70%', width: '100%', marginTop: '2rem' },
                    imgStyle: { objectFit: 'contain', objectPosition: 'center top' },
                },
            }}
            backgroundImage={{
                src: 'references/case-studies/weshare/hero_bg.png',
                imgStyle: { objectFit: 'contain', objectPosition: 'left center' },
            }}
            background={'white'}
            textColor={'black'}
        />
        <TextContent
            content={{
                section: 'case.weshare.section1.name',
                title: `case.weshare.section1.title`,
                text: `case.weshare.section1.text`,
                category: ReferenceCategory.TRAVEL,
                outputs: [
                    'references.case.output.android',
                    'references.case.output.ios',
                    'references.case.output.web',
                    'references.case.output.backend',
                ],
            }}
        />
        <Gallery
            layout={GalleryLayout.ONE_THIRD}
            content={[
                {
                    background: '#37D47F',
                    image: {
                        src: 'references/case-studies/weshare/3_1.png',
                        imgStyle: { objectFit: 'contain', objectPosition: 'center bottom' },
                    },
                },
                {
                    background: 'white',
                    image: {
                        src: 'references/case-studies/weshare/3_2.jpg',
                        style: { width: '100%', height: '100%', display: 'block', margin: 'auto' },
                        imgStyle: { objectFit: 'cover' },
                    },
                },
            ]}
        />
        <TextContent
            content={{
                section: 'case.weshare.section2.name',
                title: `case.weshare.section2.title`,
                text: `case.weshare.section2.text`,
            }}
        />
        <Gallery
            layout={GalleryLayout.EQUAL}
            backgroundImage={{
                src: 'references/case-studies/weshare/4_bg.jpg',
                imgStyle: { objectFit: 'cover' },
            }}
            content={[
                {
                    image: {
                        src: 'references/case-studies/weshare/4_1.png',
                        style: { minHeight: 'unset' },
                        imgStyle: { objectFit: 'contain', objectPosition: 'center bottom' },
                    },
                },
            ]}
        />

        <TextContent
            content={{
                section: 'case.weshare.section3.name',
                title: `case.weshare.section3.title`,
                text: `case.weshare.section3.text`,
            }}
        />
        <Gallery
            layout={GalleryLayout.EQUAL}
            background={'#00AEE8'}
            fullHeight
            backgroundImage={{
                src: 'references/case-studies/weshare/6_bg.png',
                imgStyle: { objectFit: 'cover' },
            }}
            content={[
                {
                    image: {
                        src: 'references/case-studies/weshare/6_1.png',
                        style: {
                            width: '100%',
                            height: '100%',
                            margin: 'auto',
                            marginBottom: '0',
                        },
                        imgStyle: { objectFit: 'contain', objectPosition: 'center bottom' },
                    },
                },
            ]}
        />
        <TextContent
            content={{
                section: 'case.weshare.section4.name',
                title: `case.weshare.section4.title`,
                text: `case.weshare.section4.text`,
            }}
        />
        <Gallery
            layout={GalleryLayout.EQUAL}
            background={'#37D47F'}
            backgroundImage={{
                src: 'references/case-studies/weshare/5_bg.jpg',
                imgStyle: { objectFit: 'contain' },
            }}
            fullHeight
            content={[
                {
                    image: {
                        src: 'references/case-studies/weshare/5_1.png',
                        style: {
                            width: '60%',
                            height: '60%',
                            margin: 'auto',
                        },
                        imgStyle: { objectFit: 'contain', objectPosition: 'center center' },
                    },
                },
            ]}
        />
        <Team content={team} />
    </>
);

export default WeShare;
